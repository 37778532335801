let languages = {
    ['fi'] : {
        header: 'Asiakastyytyväisyyskysely ',
        bodytext: 'Olemme toimittaneet teille betonituotteita kuluneen vuoden aikana. Laatujärjestelmämme olennaisena osana on seurata asiakkaidemme tyytyväisyyttä toimintaamme. Toteuttamme vuosittain laatujärjestelmämme mukaisen asiakastyytyväisyyskyselyn, jossa pyydämme palautetta toimintaamme 12 kysymyksellä asteikolla 4-10.',
        works: 'Projekti',
        responder: 'Vastaaja',

        //Form
        no_data: 'Ei tietoa',
        q1: '1. Tarjous ja kaupanteko',
        q2: '2. Informaation kulku',
        q3: '3. Yhteistyön sujuvuus',
        q4: '4. Aikataulujen pitävyys',
        q5: '5. Autonkuljettajan toiminta työmaalla',
        q6: '6. Yhteistyö toimituksen aikana',
        q7: '7. Elementtien ulkonäkö',
        q8: '8. Elementtien tekninen laatu',
        q9: '9. Asennustyön suorittaminen (jos asennus kuului urakkaan)',
        q10: '10. Jälkitöiden hoito',
        q11: '11. Hinta/laatu -suhde',
        q12: '12. Yleisarvosana toimituksesta',

        other: 'Muut huomautukset ja palaute:',
        tell_opinion: 'Kerro mielipiteesi?',

        gift: 'YBT Asiakaslahja',
        gift_label: 'YBT Joulupaketti - Lähetämme jokaiselle kyselyyn vastanneelle asiakkaallemme YBT:n perinteisen joulupaketin. Täytä alle tietosi joulupaketin lähettämiseksi.',
        gift_receiver: 'Vastaanottaja',
        gift_address: 'Katuosoite',
        gift_postal: '00000 Paikkakunta',

        send: 'Lähetä',
        want_send: 'Haluatko lähettää vastauksen?',
        yes: 'Kyllä',
        no: 'En',
        sent: 'Lähetetty!',
        thank_sent: 'Kiitos kyselyyn vastaamisesta!',

        som_wrong:'Jotain meni vikaan!',
        som_wrong_text: "Yritä hetken päästä uudelleen.",
    },
    ['swe'] : {
        header: 'Kundundersökning ',
        bodytext: 'Vi har under det gångna året levererat betongprodukter till er. Som en väsentlig del i vårt kvalitetssystem är uppföljningen av hur nöjda kunderna är med vår verksamhet. Vi gör årligen en enkät om kundnöjdhet där vi ber om omdömen för vår verksamhet i form av 12 frågor som ska besvaras på en skala 4-10.',
        works: 'Projekt',
        responder: 'Bedömare',

        //Form
        no_data: 'Vet Ej',
        q1: '1. Offert och upphandling',
        q2: '2. Informationsflödet',
        q3: '3. Samarbetet',
        q4: '4. Hur hålls tidplaner',
        q5: '5. Chaufförens agerande på arbetsplatsen',
        q6: '6. Samarbetet under leveransen',
        q7: '7. Utseendet på elementen',
        q8: '8. Elementens tekniska kvalitet',
        q9: '9. Montage utförande (om montage ingår)',
        q10: '10. Hur har efterarbeten skötts',
        q11: '11. Förhållandet pris/kvalitet',
        q12: '12. Det allmänna omdömet',

        other: 'Noteringar och kommentarer:',
        tell_opinion: 'Berätta din åsikt?',

        gift: 'YBT kundpresent',
        gift_label: 'YBT julpaket – Vi skickar ett traditionellt YBT julpaket till varje kund som besvarat enkäten. Fyll i dina uppgifter nedan för att skicka ett julpaket.',
        gift_receiver: 'Mottagaren',
        gift_address: 'Adress',
        gift_postal: '00000 Stad',

        send: 'Skicka',

        want_send: 'Vill du skicka ett svar?',
        yes: 'Ja',
        no: 'Nej',
        sent: 'Skickat!',
        thank_sent: 'Tack för att du svarade på enkäten!',

        som_wrong:'Något gick fel!!',
        som_wrong_text: 'Vänligen försök igen senare.',
    },
}

export default languages

