import { Routes, Route} from "react-router-dom";
import './App.css';
import Form from '../Form/Form';
import { useEffect } from "react";

import logo from "../../assets/images/ybtlogosmall.png"; // Tell webpack this JS file uses this image


function Aloitus() {
  useEffect(() => {
    window.location.replace('https://www.ybt.fi');
  }, []);

  return <></>
}

function App() {
  return (
    <div className="App">
              <div className="header">
          <img
            className="headerlogo"
            src={logo}
            alt="YBT Oy"
            onClick={() => {
              window.open("https://www.ybt.fi/", "_blank");
            }}
            href="someLink"
          />
         {/*  <LanguageSwitcher additionalClasses={"header-language"} /> */}
        </div>
      <Routes>
        <Route path="/:id" element={<Form />} />
        <Route path="*" element={<Aloitus />} />
      </Routes>
    </div>
  );
}

export default App;
