const colors = {
  button: "#2B4BF2",
  buttontext: "#FFFFFF",
  on: "#14DC40",
  mid: "#CDAF14",
  off: "#CD1414",
  gray: "#676666",
  lightgray: "#8a8a8a",
  orange: "#CD3514",
  clickableText: '#3478F6',
};

const themes = {
  light: {
    ...colors,
    main: "#FFFFFF",
    surface1: "#FAFAFA",
    text: "#000000",
    form: "#F7F9FC",
    formborder: "#2B4BF2",
    search: "#EFEFEF",
    buttonoff: "#676666",
    divider: "#676666",
    graytext: "#575757"
  },
  dark: {
    ...colors,
    main: "#000000",
    surface1: "#282828",
    text: "#FFFFFF",
    form: "#282828",
    formborder: "#727272",
    search: "#474747",
    buttonoff: "#676666",
    divider: "#474747",
    graytext: "#B0AFAF"
  },
};

export const useTheme = () => {
  //const devicetheme = useColorScheme();
  //const colors = theme ? themedColors[theme] : themedColors.default

  //console.log("Teema12:", devicetheme);
  const theme = themes["light"] //devicetheme === "dark" ? themes["dark"] : themes["light"];

  return {
    theme
  };
};
