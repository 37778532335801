import languages from "../languages/languages";



export const useTranslate = (lang) => {
  //const { lang } = useSelector((state) => state.currentUser.user);
  let localeFound = false

  if (Object.keys(languages).includes(lang)) localeFound = true
  
  const locale = localeFound ? languages[lang] : languages['fi']

  return {
    locale
  };
};
