import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { api } from "../../components/api";
import { formatdata } from "../../components/globalFunctions";
import { Loading } from "../../components/Loading/Loading";
import { useTranslate } from "../../components/Translate";
import { useTheme } from "../../config/themes";
import "./Form.css";

function FormItem({ data = null, setData = () => {}, label = "", index = 0, locale={} }) {
  return (
    <>
      <div className="checkboxContainer">
        <p className="checkboxHeader fontheaders font-dm-bold">{label}</p>

        <div className="checkboxItems">
          <div>
            <input
              id={index + "valuenull"}
              name={index + "valuenull"}
              type="checkbox"
              checked={data === null}
              onChange={() => setData(null)}
            />
            <label className="font-dm-medium fontbasic" htmlFor={index + "valuenull"}>{locale.no_data}</label>
          </div>

          <div>
            <input
              id={index + "value4"}
              name={index + "value4"}
              type="checkbox"
              checked={data === 4}
              onChange={() => setData(4)}
            />
            <label className="font-dm-medium fontbasic" htmlFor={index + "value4"}>4</label>
          </div>

          <div>
            <input
              id={index + "value5"}
              name={index + "value5"}
              type="checkbox"
              checked={data === 5}
              onChange={() => setData(5)}
            />
            <label className="font-dm-medium fontbasic" htmlFor={index + "value5"}>5</label>
          </div>

          <div>
            <input
              id={index + "value6"}
              name={index + "value6"}
              type="checkbox"
              checked={data === 6}
              onChange={() => setData(6)}
            />

            <label className="font-dm-medium fontbasic" htmlFor={index + "value6"}>6</label>
          </div>

          <div>
            <input
              id={index + "value7"}
              name={index + "value7"}
              type="checkbox"
              checked={data === 7}
              onChange={() => setData(7)}
            />

            <label className="font-dm-medium fontbasic" htmlFor={index + "value7"}>7</label>
          </div>

          <div>
            <input
              id={index + "value8"}
              name={index + "value8"}
              type="checkbox"
              checked={data === 8}
              onChange={() => setData(8)}
            />

            <label className="font-dm-medium fontbasic" htmlFor={index + "value8"}>8</label>
          </div>

          <div>
            <input
              id={index + "value9"}
              name={index + "value9"}
              type="checkbox"
              checked={data === 9}
              onChange={() => setData(9)}
            />

            <label className="font-dm-medium fontbasic" htmlFor={index + "value9"}>9</label>
          </div>

          <div>
            <input
              id={index + "value10"}
              name={index + "value10"}
              type="checkbox"
              checked={data === 10}
              onChange={() => setData(10)}
            />

            <label className="font-dm-medium fontbasic" htmlFor={index + "value10"}>10</label>
          </div>
        </div>
      </div>
    </>
  );
}

const Form = () => {
  let { id } = useParams();

  const { theme } = useTheme();

  


  const [isLoading, setisLoading] = useState(true);
  const [isSending, setisSending] = useState(false);

  const [formData, setFormData] = useState([]);

  const [topic1, setTopic1] = useState(null);
  const [topic2, setTopic2] = useState(null);
  const [topic3, setTopic3] = useState(null);
  const [topic4, setTopic4] = useState(null);
  const [topic5, setTopic5] = useState(null);
  const [topic6, setTopic6] = useState(null);
  const [topic7, setTopic7] = useState(null);
  const [topic8, setTopic8] = useState(null);
  const [topic9, setTopic9] = useState(null);
  const [topic10, setTopic10] = useState(null);
  const [topic11, setTopic11] = useState(null);
  const [topic12, setTopic12] = useState(null);

  const [huom, setHuom] = useState("");
  const [receiver, setReceiver] = useState('');
  const [addr, setAddr] = useState("");
  const [postal, setPostal] = useState("");


  const { locale } = useTranslate(formData?.tiedot?.Oletuskieli);

  //console.log("ID:", id);

  const getFormdata = () => {
    let req_config = {
      method: "POST",
      url: `/getFormdata`,
      data: {
        formId: id,
      },
    };

    api(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        setFormData(response.data.data);
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
   /*  console.log("formData updated"); */
    if (formData?.tiedot?.Henkilo) {
      /* console.log("formData updating person, ", formData?.tiedot?.Henkilo); */
      setReceiver(formData?.tiedot?.Henkilo)
    }

    if (formData?.tiedot?.KyselyRyhma) {
      document.title = "YBT Oy - "+ formData?.tiedot?.KyselyRyhma
    }

  }, [formData]);

  useEffect(() => {
    getFormdata();
    console.log("useEffect called");
  }, []);

  const sendFormdata = async (e) => {
    e.preventDefault();

    let confirmed = await Swal.fire({
      customClass:"swalCustom",
      title: locale.want_send,
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonColor: "#2B4BF2",
      confirmButtonText: locale.yes,
      cancelButtonText: locale.no,
    }).then((result) => {
      console.log(result);

      if (result.isConfirmed) {
        return true;
      } else if (result.isDenied) {
        return false;
      } else if (result.isDismissed) {
        return false;
      }
    });

    if (!confirmed) return;

    console.log("LÄHETEÄÄN", confirmed);

    setisSending(true);

    let req_config = {
      method: "POST",
      url: `/sendFormdata`,
      data: {
        formId: id,
        topic1: topic1,
        topic2: topic2,
        topic3: topic3,
        topic4: topic4,
        topic5: topic5,
        topic6: topic6,
        topic7: topic7,
        topic8: topic8,
        topic9: topic9,
        topic10: topic10,
        topic11: topic11,
        topic12: topic12,
        huom: formatdata(huom),
        receiver: formatdata(receiver),
        addr: formatdata(addr),
        postal: formatdata(postal),
      },
    };

    console.log("SENDING:", req_config);

    api(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);

        Swal.fire({
          icon: "success",
          title: "Lähetetty!",
          text: "Kiitos kyselyyn vastaamisesta!",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        }).then((result) => {
          window.location.replace("https://www.ybt.fi");
        });
      })
      .catch((error) => {
        console.log("Error:", error);
        setisSending(false);

        Swal.fire({
          icon: "error",
          title: locale.som_wrong,
          text: locale.som_wrong_text,
        });
      });
  };


  
  if (isLoading) {
    return <Loading />;
  }



  if (!formData.tiedot) {
    Swal.fire({
      icon: "error",
      title: "Jotain meni vikaan!",
      text: "Lomaketta ei löytynyt kyseisellä linkillä!",
      showConfirmButton: false,
      timer: 10*1000,
      timerProgressBar: true,
    }).then((result) => {
      window.location.replace("https://www.ybt.fi");
    });

    return <></>;
  }

  return (
    <>
      <main>
        <div className="form-data">
          <h2 className="form-header font-itc">
            {formData.tiedot.KyselyRyhma}
          </h2>

          <p
            className="form-int fontbasic font-dm-regular"
            style={{ color: theme.graytext }}
          >
            {locale.bodytext}
          </p>

          <h3 className="form-projects fontheaders font-itc">{locale.works}</h3>
          {formData.tyokohteet.length >= 1 &&
            formData.tyokohteet.map((item, index) => {
              return (
                <p
                  className="form-projects fontbasic font-dm-regular"
                  style={{ color: theme.graytext }}
                  key={index}
                >
                  {item.Nimi}
                </p>
              );
            })}

          <p>
            <span className="font-dm-regular fontbasic" style={{ color: theme.graytext }}>
              {locale.responder}{" "}
            </span>
            <span className="font-dm-bold fontbasic">

              {formData.tiedot.Henkilo && formData.tiedot.Henkilo}

              {formData.tiedot.Henkilo && formData.tiedot.Yritys && ", "}

              {formData.tiedot.Yritys && formData.tiedot.Yritys}

            </span>
          </p>
        </div>

        <hr />
        <div>
          <FormItem
            data={topic1}
            setData={setTopic1}
            index={1}
            label={locale.q1}
            locale={locale}
          />
          <FormItem
            data={topic2}
            setData={setTopic2}
            index={2}
            label={locale.q2}
            locale={locale}
          />
          <FormItem
            data={topic3}
            setData={setTopic3}
            index={3}
            label={locale.q3}
            locale={locale}
          />
          <FormItem
            data={topic4}
            setData={setTopic4}
            index={4}
            label={locale.q4}
            locale={locale}
          />
          <FormItem
            data={topic5}
            setData={setTopic5}
            index={5}
            label={locale.q5}
            locale={locale}
          />
          <FormItem
            data={topic6}
            setData={setTopic6}
            index={6}
            label={locale.q6}
            locale={locale}
          />
          <FormItem
            data={topic7}
            setData={setTopic7}
            index={7}
            label={locale.q7}
            locale={locale}
          />
          <FormItem
            data={topic8}
            setData={setTopic8}
            index={8}
            label={locale.q8}
            locale={locale}
          />
          <FormItem
            data={topic9}
            setData={setTopic9}
            index={9}
            label={locale.q9}
            locale={locale}
          />
          <FormItem
            data={topic10}
            setData={setTopic10}
            index={10}
            label={locale.q10}
            locale={locale}
          />
          <FormItem
            data={topic11}
            setData={setTopic11}
            index={11}
            label={locale.q11}
            locale={locale}
          />
          <FormItem
            data={topic12}
            setData={setTopic12}
            index={12}
            label={locale.q12}
            locale={locale}
          />
        </div>

        <br />
        <br />

        <div>
          <p className="font-dm-bold fontheaders form-data" style={{marginBottom:0}}>
            {locale.other}
          </p>
          <textarea
            className="form-textinput form-big font-dm-regular"
            name="huom"
            placeholder={locale.tell_opinion}
            defaultValue={huom}
            onChange={(event) => {
              setHuom(event.target.value);
            }}
          />
        </div>

        <br />

        <div>
          <p className="font-dm-bold fontheaders form-data" style={{marginBottom:0}}>{locale.gift}</p>
          <p className="font-dm-regular fontbasic form-data graytext" style={{marginTop:5}}>{formData?.tiedot.KysyOsoite ? locale.gift_label : formData?.tiedot.Asiakaslahja}</p>

          {formData?.tiedot.KysyOsoite && (
            <>
              <input
                className="form-textinput font-dm-regular"
                type="text"
                name="receiver"
                placeholder={locale.gift_receiver}
                value={receiver}
                onChange={(event) => {
                  setReceiver(event.target.value);
                }}
              />
              <label
                className="form-inputlabel font-dm-medium graytext"
                htmlFor={"postal"}
              >
                {locale.gift_receiver}
              </label>

              <br />

              <input
                className="form-textinput font-dm-regular"
                type="text"
                name="address"
                placeholder={locale.gift_address + " 1"}
                value={addr}
                onChange={(event) => {
                  setAddr(event.target.value);
                }}
              />
              <label
                className="form-inputlabel font-dm-medium graytext"
                htmlFor={"address"}
              >
                {locale.gift_address}
              </label>

              <br />

              <input
                className="form-textinput font-dm-regular"
                type="text"
                name="postal"
                placeholder={locale.gift_postal}
                value={postal}
                onChange={(event) => {
                  setPostal(event.target.value);
                }}
              />
              <label
                className="form-inputlabel font-dm-medium graytext"
                htmlFor={"postal"}
              >
                {locale.gift_postal}
              </label>
            </>
          )}

        </div>

        <button className="sendBtn font-dm-regular" onClick={sendFormdata} disabled={isSending}>
          {locale.send}
        </button>

        <br />
      </main>
    </>
  );
};

export default Form;
